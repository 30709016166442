import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Architectes from '../scenes/LandingPages/Architectes';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Architects`,
    description: "Structural Analysis for Architects"
  },
  fr: {
    title: `TriDyme | Architectes`,
    description: "TriDyme optimise les maquettes d'architecture dès la phase esquisse"
  }
};

const LandingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <Architectes />
  </Layout>
)

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default LandingPage;